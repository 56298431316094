import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a5418a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "d-flex flex-wrap justify-content-start align-items-start"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!$setup.selectedStore.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "ไม่ได้เลือก"))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.selectedStore, (store) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "commission-wrap",
              key: store.id
            }, [
              _createTextVNode(_toDisplayString(store.name) + " ", 1),
              _createElementVNode("button", {
                type: "button",
                class: "btn-close",
                onClick: ($event: any) => ($setup.remove(store.id))
              }, null, 8, _hoisted_3)
            ]))
          }), 128))
        ]))
  ]))
}