
import { computed } from '@vue/runtime-core';
import { dateFormat } from '@/utils/helper/format';
export default {
  components: {},
  props: {
    modelValue: {
      type: Array,
      default: () => [] as any[],
    },
  },
  setup(props, { emit }) {
    const periods = computed<any[]>({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {
      items: computed(() => {
        return periods.value.map((period) => {
          return `${dateFormat(period.start, 'h:mm A')} - ${dateFormat(
            period.end,
            'h:mm A'
          )}`;
        });
      }),
      remove(idx: number) {
        if (idx > -1) {
          periods.value.splice(idx, 1);
        }
      },
    };
  },
};
