
import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';
import { Getters, Store } from '@/store/modules/StoreModule';
export default {
  components: {},
  props: {
    modelValue: {
      type: Array,
      default: () => [] as any[],
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const selectedId = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const stores = computed<Store[]>(() => store.getters[Getters.STORES]);

    return {
      selectedStore: computed(() =>
        stores.value.filter((s: Store) => selectedId.value.includes(s.id))
      ),
      remove(id) {
        const idx = selectedId.value.indexOf(id);

        if (idx > -1) {
          selectedId.value.splice(idx, 1);
        }
      },
    };
  },
};
