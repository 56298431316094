
import {
  Actions as ServicePointAction,
  Getters as ServicePointGetters,
} from '@/store/modules/ServicePointModule';
import {
  Actions as ProvinceActions,
  Getters as ProvinceGetters,
} from '@/store/modules/ProvinceModule';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from '@vue/runtime-core';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { dateFormat } from '@/utils/helper/format';

const tableHeader = [
  {
    name: '#',
    key: 'selectStore',
    sortable: false,
  },
  {
    name: 'Location',
    key: 'name',
    sortable: true,
  },
  {
    name: 'Province',
    key: 'provinceName',
    sortable: true,
  },
];

export default {
  components: {
    Datatable,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [] as any[],
    },
  },
  setup(props, { emit }) {
    const tableRef = ref<any>(null);
    const tableData = ref<any[]>([]);
    const selectedProvince = ref<any>(null);
    const store = useStore();
    const modalRef = ref(null);
    const savedSelectedId = ref<any[]>([]);
    const selectedId = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    onMounted(async () => {
      await Promise.all([
        store.dispatch(`${ProvinceActions.FETCH_ALL_PROVINCE}`),
        store.dispatch(`${ServicePointAction.FETCH_ALL_SERVICE_POINT}`),
      ]);

      const tmp = [...servicePoints.value];
      tableData.value = tmp.splice(0, 10);
    });

    const servicePoints = computed(
      () => store.getters[ServicePointGetters.SERVICE_POINTS]
    );

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      ([rows, page]) => {
        const tmp = [...servicePoints.value];
        tableData.value = tmp.splice((page - 1) * rows, rows);
      }
    );

    return {
      selectedProvince,
      handleChangeProvince: async (e) => {
        await store.dispatch(`${ServicePointAction.FETCH_ALL_SERVICE_POINT}`, {
          province: e.target.value,
        });

        const tmp = [...servicePoints.value];
        tableData.value = tmp.splice(0, 10);
      },
      tableRef,
      tableData,
      provinces: computed(() => store.getters[ProvinceGetters.PROVINCES]),
      isServicePointsLoading: computed(
        () => store.getters[ServicePointGetters.IS_LOADING]
      ),
      totalServicePoints: computed(
        () => store.getters[ServicePointGetters.TOTAL]
      ),
      servicePoints,
      modalRef,
      tableHeader,
      dateFormat,
      savedSelectedId,
      selectedId,
      handleSelected: (e) => {
        e.preventDefault();
        if (e.target.checked) {
          selectedId.value.push(e.srcElement.dataset.id);
        } else {
          const idx = selectedId.value.indexOf(e.srcElement.dataset.id);

          if (idx > -1) {
            selectedId.value.splice(idx, 1);
          }
        }
      },
      saveBeforeChange() {
        savedSelectedId.value = [...selectedId.value];
      },
      loadSavedSelectedId() {
        selectedId.value = [...savedSelectedId.value];
      },
    };
  },
};
