
import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';
import { Getters, Series } from '@/store/modules/SeriesModule';
export default {
  components: {},
  props: {
    modelValue: {
      type: Array,
      default: () => [] as any[],
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const selectedId = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const stores = computed<Series[]>(() => store.getters[Getters.SERIES]);

    return {
      selectedItems: computed(() =>
        stores.value.filter((s: Series) => selectedId.value.includes(s.id))
      ),
      remove(id: string) {
        const idx = selectedId.value.indexOf(id);

        if (idx > -1) {
          selectedId.value.splice(idx, 1);
        }
      },
    };
  },
};
