
import { computed, onMounted, ref, watch } from '@vue/runtime-core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { dateFormat } from '@/utils/helper/format';

export default {
  components: {},
  props: {
    modelValue: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const modalRef = ref(null);
    const periods = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const formData = ref<any>({
      start: null,
      startTime: null,
      end: null,
      endTime: null,
    });

    const resetForm = () => {
      formData.value = {
        start: null,
        startTime: null,
        end: null,
        endTime: null,
      };
    };

    onMounted(async () => {
      resetForm();
    });

    watch(
      () => [formData.value.startTime],
      async ([time]) => {
        if (formData.value.end && time?.getTime() > formData.value.end) {
          formData.value.endTime = formData.value.startTime;
        }
        formData.value.start = time?.getTime();
      }
    );

    watch(
      () => [formData.value.endTime],
      async ([time]) => {
        if (formData.value.start && time?.getTime() < formData.value.start) {
          formData.value.endTime = formData.value.startTime;
        }
        formData.value.end = time?.getTime();
      }
    );
    const handleSubmit = () => {
      const currentPeriods = periods?.value || [];
      const foundPeriod = currentPeriods.find(
        (period) =>
          dateFormat(period.start, 'h:mm') ==
            dateFormat(formData.value.start, 'h:mm') &&
          dateFormat(period.end, 'h:mm') ==
            dateFormat(formData.value.end, 'h:mm')
      );

      const isSameTime =
        dateFormat(formData.value.start, 'h:mm') ==
        dateFormat(formData.value.end, 'h:mm');
      if (isSameTime) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-right',
          iconColor: '',
          customClass: {
            popup: 'colored-toast',
          },
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: 'error',
          title: 'เวลาเริ่มต้นและสิ้นสุดไม่ควรเป็นเวลาเดียวกัน',
        });
      } else if (!foundPeriod) {
        periods.value?.push({
          start: formData.value.start,
          end: formData.value.end,
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-right',
          iconColor: '',
          customClass: {
            popup: 'colored-toast',
          },
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: 'error',
          title: 'ขออภัย Period นี้มีอยู่แล้ว',
        });
      }
    };

    return {
      periods,
      modalRef,
      formData,
      resetForm,
      handleSubmit,
    };
  },
};
