
import { Actions, Getters } from '@/store/modules/CarModelModule';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from '@vue/runtime-core';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { dateFormat } from '@/utils/helper/format';

const tableHeader = [
  {
    name: '#',
    key: 'selectAction',
    sortable: false,
  },
  {
    name: 'Brand',
    key: 'name',
    sortable: true,
  },
];

export default {
  components: {
    Datatable,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [] as any[],
    },
  },
  setup(props, { emit }) {
    const tableRef = ref<any>(null);
    const tableData = ref<any[]>([]);
    const store = useStore();
    const modalRef = ref(null);
    const savedSelectedId = ref<any[]>([]);
    const selectedId = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    const series = computed(() => store.getters[Getters.CAR_MODELS]);

    onMounted(async () => {
      await store.dispatch(`${Actions.FETCH_ALL_CAR_MODEL}`);

      const tmp = [...series.value];
      tableData.value = tmp.splice(0, 10);
    });

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        const tmp = [...series.value];
        tableData.value = tmp.splice((page - 1) * rows, rows);
      }
    );

    return {
      savedSelectedId,
      tableRef,
      tableData,
      modalRef,
      tableHeader,
      dateFormat,
      series,
      selectedId,
      isStoreLoading: computed(() => store.getters[Getters.IS_LOADING]),
      totalStore: computed(() => store.getters[Getters.TOTAL]),
      handleSelected: (e) => {
        e.preventDefault();
        if (e.target.checked) {
          selectedId.value.push(e.srcElement.dataset.id);
        } else {
          const idx = selectedId.value.indexOf(e.srcElement.dataset.id);

          if (idx > -1) {
            selectedId.value.splice(idx, 1);
          }
        }
      },
      saveBeforeChange() {
        savedSelectedId.value = [...selectedId.value];
      },
      loadSavedSelectedId() {
        selectedId.value = [...savedSelectedId.value];
      },
    };
  },
};
