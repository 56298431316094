
import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';
import { Getters, ServicePoint } from '@/store/modules/ServicePointModule';
export default {
  components: {},
  props: {
    modelValue: {
      type: Array,
      default: () => [] as any[],
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const selectedId = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const items = computed<ServicePoint[]>(
      () => store.getters[Getters.SERVICE_POINTS]
    );

    return {
      selectedItems: computed(() =>
        items.value.filter((s: ServicePoint) => selectedId.value.includes(s.id))
      ),
      remove(id) {
        const idx = selectedId.value.indexOf(id);

        if (idx > -1) {
          selectedId.value.splice(idx, 1);
        }
      },
    };
  },
};
