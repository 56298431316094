
import { Actions, PromotionPayload } from '@/store/modules/PromotionModule';
import { onMounted, reactive, ref, toRefs, watch } from 'vue';

import { useRoute, useRouter } from 'vue-router';
import IconPallette from '@/components/icon/IconPallette.vue';
import {
  PromotionTypeEnum,
  AmountTypeEnum,
  PaymentTypes,
  ServicesTypes,
} from '../../core/data/constance';
import { mergeDateTime } from '@/utils/helper/format';
import AddCarBrandCondition from './components/AddCarBrandCondition.vue';
import AddCarModelCondition from './components/AddCarModelCondition.vue';
import AddCarSeriesCondition from './components/AddCarSeriesCondition.vue';
import AddPackageCondition from './components/AddPackageCondition.vue';
import AddPeriodCondition from './components/AddPeriodCondition.vue';
import AddServicePointCondition from './components/AddServicePointCondition.vue';
import AddStoreCondition from './components/AddStoreCondition.vue';
import BrandConditionList from './components/BrandConditionList.vue';
import CarModelConditionList from './components/CarModelConditionList.vue';
import PackageConditionList from './components/PackageConditionList.vue';
import SeriesConditionList from './components/SeriesConditionList.vue';
import ServicePointConditionList from './components/ServicePointConditionList.vue';
import StoreConditionList from './components/StoreConditionList.vue';
import PeriodConditionList from './components/PeriodConditionList.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useStore } from 'vuex';

import { Promotion } from '@/store/modules/PromotionModule';
import { Actions as CouponActions, Coupon } from '@/store/modules/CouponModule';
import { numberFormat } from '@/utils/helper/format';

export default {
  name: 'AddEditPromotion',
  components: {
    IconPallette,
    AddStoreCondition,
    StoreConditionList,
    AddPackageCondition,
    PackageConditionList,
    AddServicePointCondition,
    ServicePointConditionList,
    AddCarBrandCondition,
    BrandConditionList,
    AddCarSeriesCondition,
    SeriesConditionList,
    AddCarModelCondition,
    CarModelConditionList,
    AddPeriodCondition,
    PeriodConditionList,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const formRef = ref(null);
    const state = reactive({
      loaded: false,
      promotionId: '' as string,
      loadedPromotion: {} as Promotion,
      coupons: [] as Coupon[],
      couponTotal: 0,
      isAddCoupon: false,
      formData: {
        id: undefined,
        name: null,
        description: null,
        amount: null,
        amountType: undefined,
        isMinPurchase: false,
        minPurchase: null,
        isMaxDiscount: false,
        maxDiscount: null,
        isLimitPerUser: false,
        startDate: 0,
        endDate: 0,
        startDatePath: undefined,
        startTimePath: undefined,
        endDatePath: undefined,
        endTimePath: undefined,
        type: null, // fix type
        code: null,
        quantity: null, //amount use for...
        limitPerUser: null, // amount per user

        //condition
        isPaymentType: false,
        paymentTypes: [], // map from paymentTypesOption
        paymentTypesOption: {
          [PaymentTypes.QR]: false,
          [PaymentTypes.CREDIT]: false,
        },
        isCarPackage: false,
        carPackages: [],
        carPackagesOption: {
          [ServicesTypes[0].value]: false,
          [ServicesTypes[1].value]: false,
          [ServicesTypes[2].value]: false,
        },
        isStore: false, //boolean
        stores: [], //string[]
        isPackage: false,
        packages: [],
        isServicePoint: false, //boolean
        servicePoints: [], //string[]
        isCustomCar: false, //boolean
        isBrands: true,
        brands: [], //string[]
        isModels: true,
        models: [], //string[]
        isSeries: true,
        series: [], //string[]
        isPeriod: false,
        periods: [],
        isActive: false,
      } as PromotionPayload,
    });

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: '',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    watch(
      () => [
        state.formData.paymentTypesOption[PaymentTypes.QR],
        state.formData.paymentTypesOption[PaymentTypes.CREDIT],
      ],
      ([qr, credit]) => {
        if (!state.formData.paymentTypes) {
          state.formData.paymentTypes = [];
        }
        if (qr) {
          if (!state.formData.paymentTypes?.includes(PaymentTypes.QR)) {
            state.formData.paymentTypes.push(PaymentTypes.QR);
          }
        } else {
          const idx = state.formData.paymentTypes.indexOf(PaymentTypes.QR);

          if (idx > -1) {
            state.formData.paymentTypes.splice(idx, 1);
          }
        }

        if (credit) {
          if (!state.formData.paymentTypes.includes(PaymentTypes.CREDIT)) {
            state.formData.paymentTypes.push(PaymentTypes.CREDIT);
          }
        } else {
          const idx = state.formData.paymentTypes.indexOf(PaymentTypes.CREDIT);
          if (idx > -1) {
            state.formData.paymentTypes.splice(idx, 1);
          }
        }
      }
    );

    watch(
      () => [
        state.formData.carPackagesOption[ServicesTypes[0].value],
        state.formData.carPackagesOption[ServicesTypes[1].value],
        state.formData.carPackagesOption[ServicesTypes[2].value],
      ],
      (values) => {
        values.forEach((value, i) => {
          if (!state.formData.carPackages) {
            state.formData.carPackages = [];
          }

          if (value) {
            if (!state.formData.carPackages.includes(ServicesTypes[i].value)) {
              state.formData.carPackages.push(ServicesTypes[i].value);
            }
          } else {
            const idx = state.formData.carPackages.indexOf(
              ServicesTypes[i].value
            );

            if (idx > -1) {
              state.formData.carPackages.splice(idx, 1);
            }
          }
        });
      }
    );

    watch(
      () => [state.formData.startDatePath],
      ([date]) => {
        state.formData.startTimePath = date;

        if (
          state.formData.endDatePath &&
          state.formData.startDatePath &&
          state.formData.startDatePath > state.formData.endDatePath
        ) {
          state.formData.endDatePath = state.formData.startDatePath;
        }
      }
    );

    watch(
      () => [state.formData.endDatePath],
      async ([date]) => {
        state.formData.endTimePath = date;
      }
    );

    watch(
      () => [state.formData.startTimePath],
      async ([time]) => {
        const startDate = mergeDateTime(
          state.formData.startDatePath || new Date(0).toISOString(),
          time || state.formData.startDatePath || new Date(0).toISOString()
        );

        state.formData.startDate = startDate ? startDate.getTime() : 0;
      }
    );

    watch(
      () => [state.formData.endTimePath],
      async ([time]) => {
        const endDate = mergeDateTime(
          state.formData.endDatePath || new Date(0).toISOString(),
          time || state.formData.endDatePath || new Date(0).toISOString()
        );
        state.formData.endDate = endDate ? endDate.getTime() : 0;
      }
    );

    watch(
      () => route.params.promotionId,
      async (promotionId) => {
        if (promotionId) {
          state.promotionId = `${promotionId}`;
          const promotion = await store.dispatch(
            `${Actions.GET_PROMOTION_BY_ID}`,
            promotionId
          );

          state.formData = await store.dispatch(
            `${Actions.MAP_PROMOTION_TO_PAYLOAD}`,
            promotion
          );
        }
      }
    );

    onMounted(async () => {
      const { promotionId } = route.params;

      if (promotionId) {
        state.promotionId = `${promotionId}`;

        try {
          const promotion = await store.dispatch(
            `${Actions.GET_PROMOTION_BY_ID}`,
            promotionId
          );

          state.formData = await store.dispatch(
            `${Actions.MAP_PROMOTION_TO_PAYLOAD}`,
            promotion
          );
        } catch (error: any) {
          console.error({ message: error.message });
        }

        const { datas, total } = await store.dispatch(
          CouponActions.FETCH_COUPON,
          {
            promotionId,
          }
        );
        state.coupons = datas;
        state.couponTotal = total;

        if (state.formData.type == PromotionTypeEnum.FIX) {
          state.formData.quantity = state.coupons[0].quantity;
        }
      }
      state.loaded = true;
    });

    const elRules = reactive({
      name: [
        {
          required: true,
          message: 'Please input promotion name',
          trigger: 'change',
        },
        {
          pattern: /^[ก-ฮเ-ไA-Za-z0-9][ก-๙A-Za-z0-9.-\\ ]*$/,
          trigger: 'change',
          message: 'Invalid promotion name',
        },
      ],
      code: [
        {
          required: true,
          message: 'Please input promotion code',
          trigger: 'change',
        },
        {
          pattern: /^[ก-ฮเ-ไA-Za-z0-9][ก-๙A-Za-z0-9.-]*$/,
          trigger: 'change',
          message: 'Invalid promotion code',
        },
        {
          min: 6,
          trigger: 'change',
          message: 'Promotion Code must at lease 6 character',
        },
        {
          max: 10,
          trigger: 'change',
          message: 'Promotion Code maximum is 10 character',
        },
      ],
      isNewUser: [
        {
          type: 'boolean',
          required: true,
          message: 'Please select target',
          trigger: 'change',
        },
      ],
      amountType: [
        {
          type: 'string',
          required: true,
          message: 'Please select type',
          trigger: 'change',
        },
      ],
      type: [
        {
          type: 'string',
          required: true,
          message: 'Please select fix type',
          trigger: 'change',
        },
      ],
      startDatePath: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a date',
          trigger: 'change',
        },
      ],
      startTimePath: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a time',
          trigger: 'change',
        },
      ],
      endDatePath: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a date',
          trigger: 'change',
        },
      ],
      endTimePath: [
        {
          type: 'date',
          required: true,
          message: 'Please pick a time',
          trigger: 'change',
        },
      ],
      amount: [
        { required: true, message: 'amount is required' },
        {
          type: 'number',
          message: 'amount must be a number',
          trigger: 'change',
        },
        {
          type: 'number',
          message: 'amount must not less than 1',
          trigger: 'change',
          min: 1,
        },
      ],
      minPurchase: [
        {
          required: true,
          message: 'Min Purchase is required',
        },
        {
          type: 'number',
          message: 'Min Purchase must be a number',
          trigger: 'change',
        },
        {
          type: 'number',
          message: 'Min Purchase must not less than 1',
          trigger: 'change',
          min: 1,
        },
      ],
      maxDiscount: [
        {
          required: true,
          message: 'Max Discount is required',
        },
        {
          type: 'number',
          message: 'Max Discount must be a number',
          trigger: 'change',
        },
        {
          type: 'number',
          message: 'Max Discount must not less than 1',
          trigger: 'change',
          min: 1,
        },
      ],
      quantity: [
        {
          required: true,
          message: 'quantity is required',
        },
        {
          type: 'number',
          message: 'quantity must be a number',
          trigger: 'change',
        },
        {
          type: 'number',
          message: 'quantity must not less than 1',
          trigger: 'change',
          min: 1,
        },
      ],
      limitPerUser: [
        {
          required: true,
          message: 'amount use per user is required',
        },
        {
          type: 'number',
          message: 'amount use per user must be a number',
          trigger: 'change',
        },
        {
          type: 'number',
          message: 'amount use per user must not less than 1',
          trigger: 'change',
          min: 1,
        },
      ],
    });

    return {
      formRef,
      ...toRefs(state),
      AmountTypeEnum,
      PromotionTypeEnum,
      PaymentTypes,
      ServicesTypes,
      numberFormat,
      async handleChangeStatus(e) {
        e.preventDefault();
        if (state.promotionId) {
          try {
            await store.dispatch(Actions.UPDATE_PROMOTION_STATUS, {
              id: state.promotionId,
              isActive: state.formData.isActive,
            });

            await Toast.fire({
              icon: 'success',
              title: 'Success',
            });
          } catch (error) {
            console.error({ error });

            await Toast.fire({
              icon: 'error',
              title: 'Failed',
            });
          }
        }
      },
      elRules,
      async handleSubmit(formEl) {
        console.log(formEl);
        if (!formEl) return;

        formEl.validate(async (valid, fields) => {
          if (valid) {
            try {
              if (state.promotionId) {
                await store.dispatch(`${Actions.UPDATE_PROMOTION}`, {
                  id: state.promotionId,
                  payload: state.formData,
                });
              } else {
                await store.dispatch(
                  `${Actions.CREATE_PROMOTION}`,
                  state.formData
                );
              }

              Swal.fire({
                text: 'Form has been successfully submitted!',
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok, got it!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                router.push({ name: 'promotionList' });
              });
            } catch (error) {
              console.error({ error });

              Swal.fire({
                text: 'Sorry, looks like there are some errors detected, please try again.',
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Ok, got it!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            }
          } else {
            const errs: string[] = [];
            Object.values(fields).forEach(async ([errField]: any) => {
              errs.push(`${errField.field}: ${errField.message}`);
            });

            await Toast.fire({
              icon: 'error',
              title: `Invalid form data`,
            });
          }
        });
        return;
      },
    };
  },
};
